import React from 'react';
import smsService from '../../../../services/sms.service';
import { Box, Tab, Tabs, Typography } from '@material-ui/core';
import logger from '../../../../commons/logger';
import { CREDIT_ORDER_STATUS } from '../../../../models/enums/CreditOrderStatus.enum';
import CreditOrderTableComponent from './components/credit-order-table/credit-order-table.component';
import CreditOrderDialogComponent from './components/credit-order-dialog/credit-order-dialog.component';
import { CreditOrder } from '../../../../models/interfaces/CreditOrder.interface';

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function CreditPackageOrderComponent(props: any) {
  const [pendingCreditOrders, setPendingCreditOrders] = React.useState<CreditOrder[]>([]);
  const [fulFilledCreditOrders, setFulFilledCreditOrders] = React.useState<CreditOrder[]>([]);
  const [rejectedCreditOrders, setRejectedCreditOrders] = React.useState<CreditOrder[]>([]);

  const [selectedTab, setSelectedTab] = React.useState<number>(0);
  const [selectedCreditOrder, setSelectedCreditOrder] = React.useState<CreditOrder>(null);
  const [isOpenDialog, setIsOpenDialog] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const getPendingCreditOrders = async () => {
    const data = await smsService.getCreditOrdersByStatus(CREDIT_ORDER_STATUS.PENDING);
    setPendingCreditOrders(data || []);
  };

  const getFulFilledCreditOrders = async () => {
    const data = await smsService.getCreditOrdersByStatus(CREDIT_ORDER_STATUS.FULFILLED);
    setFulFilledCreditOrders(data || []);
  };

  const getRejectedCreditOrders = async () => {
    const data = await smsService.getCreditOrdersByStatus(CREDIT_ORDER_STATUS.REJECTED);
    setRejectedCreditOrders(data || []);
  };

  const closeDialog = () => {
    if (isLoading) {
      return;
    }

    setSelectedCreditOrder(null);
    setIsOpenDialog(false);
  };

  const fulfillCreditOrderAndDepositCredit = async (creditOrder: CreditOrder) => {
    setIsLoading(true);

    try {
      const isSuccess = await smsService.fulfillCreditOrderAndDepositCredit(creditOrder.id);

      if (isSuccess) {
        await Promise.all([getFulFilledCreditOrders(), getPendingCreditOrders()]);
      }
    } catch (e) {
      logger.error(e);
    } finally {
      setIsLoading(false);
      closeDialog();
    }
  };

  const rejectCreditOrder = async (creditOrder: CreditOrder) => {
    setIsLoading(true);

    try {
      const isSuccess = await smsService.rejectCreditOrder(creditOrder.id);

      if (isSuccess) {
        await Promise.all([getRejectedCreditOrders(), getPendingCreditOrders()]);
      }
    } catch (e) {
      logger.error(e);
    } finally {
      setIsLoading(false);
      closeDialog();
    }
  };

  const onSelectCreditOrder = (creditOrder: CreditOrder) => {
    if (isLoading || !creditOrder) {
      return;
    }
    setSelectedCreditOrder(creditOrder);
    setIsOpenDialog(true);
  };

  React.useEffect(() => {
    const fetchCreditOrders = async () => {
      setIsLoading(true);

      try {
        await Promise.all([getPendingCreditOrders(), getFulFilledCreditOrders(), getRejectedCreditOrders()]);
      } catch (e) {
        logger.error(e);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCreditOrders();
  }, []);

  const tabChange = (event: any, tabIndex: number) => {
    setSelectedTab(tabIndex);
  };

  const getTabLabels = (smsOrderStatus: CREDIT_ORDER_STATUS) => {
    switch (smsOrderStatus) {
      case CREDIT_ORDER_STATUS.PENDING:
        return `Pending orders (${pendingCreditOrders?.length})`;
      case CREDIT_ORDER_STATUS.FULFILLED:
        return `Fulfilled orders (${fulFilledCreditOrders?.length})`;
      case CREDIT_ORDER_STATUS.REJECTED:
        return `Rejected orders (${rejectedCreditOrders?.length})`;
    }
  };

  return (
    <>
      {isOpenDialog ? (
        <CreditOrderDialogComponent
          isOpen={isOpenDialog}
          isLoading={isLoading}
          creditOrder={selectedCreditOrder}
          onClose={closeDialog}
          onFulfill={fulfillCreditOrderAndDepositCredit}
          onReject={rejectCreditOrder}
        ></CreditOrderDialogComponent>
      ) : (
        <></>
      )}

      <Tabs
        value={selectedTab}
        onChange={tabChange}
        aria-label="SMS order tabs"
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
      >
        <Tab label={getTabLabels(CREDIT_ORDER_STATUS.PENDING)} {...a11yProps(0)} />
        <Tab label={getTabLabels(CREDIT_ORDER_STATUS.FULFILLED)} {...a11yProps(1)} />
        <Tab label={getTabLabels(CREDIT_ORDER_STATUS.REJECTED)} {...a11yProps(2)} />
      </Tabs>

      <TabPanel value={selectedTab} index={0}>
        <CreditOrderTableComponent
          isLoading={isLoading}
          creditOrders={pendingCreditOrders}
          onSelect={onSelectCreditOrder}
          key={CREDIT_ORDER_STATUS.PENDING}
        ></CreditOrderTableComponent>
      </TabPanel>

      <TabPanel value={selectedTab} index={1}>
        <CreditOrderTableComponent
          isLoading={isLoading}
          creditOrders={fulFilledCreditOrders}
          onSelect={onSelectCreditOrder}
          key={CREDIT_ORDER_STATUS.FULFILLED}
        ></CreditOrderTableComponent>
      </TabPanel>

      <TabPanel value={selectedTab} index={2}>
        <CreditOrderTableComponent
          isLoading={isLoading}
          creditOrders={rejectedCreditOrders}
          onSelect={onSelectCreditOrder}
          key={CREDIT_ORDER_STATUS.REJECTED}
        ></CreditOrderTableComponent>
      </TabPanel>
    </>
  );
}
