import React from 'react';
import { LexofficeConnection } from '../../../../../models/interfaces/LexofficeConnection.interface';
import { LexofficeContact } from '../../../../../models/interfaces/LexofficeContact.interface';
import { User } from '../../../../../models/interfaces/User.interface';
import CustomDialogComponent from '../../../../../commons/components/custom-dialog/custom-dialog.component';
import { Button, Grid, MenuItem, TextField } from '@material-ui/core';
import { LEXOFFICE_TAX_TYPE } from '../../../../../models/enums/LexofficeTaxType.enum';
import { LEXOFFICE_BILLING_LANGUAGE } from '../../../../../models/enums/LexofficeBillingLanguage.enum';
import { EU_COUNTRY_CODES } from '../../../../../models/consts/EuCountryCode.const';
import { convertEnumToArray } from '../../../../../commons/utils/enum-to-array';

export interface LexofficeConnectionDataForDialog {
  connection?: LexofficeConnection;
  preselectedLexofficeContact?: LexofficeContact;
  preselectedUser?: User;
  users: User[];
  lexofficeContacts: LexofficeContact[];
}

interface LexofficeConnectionDialogComponentProps {
  isOpen: boolean;
  isLoading: boolean;
  data: LexofficeConnectionDataForDialog;
  onClose: Function;
  onCreate: Function;
  onUpdate: Function;
  onDelete: Function;
}

const taxTypeOptions = convertEnumToArray(LEXOFFICE_TAX_TYPE);
const billingLanguageOptions = convertEnumToArray(LEXOFFICE_BILLING_LANGUAGE);

export function LexofficeConnectionDialogComponent(props: LexofficeConnectionDialogComponentProps) {
  const [selectedUserId, setSelectedUserId] = React.useState<number>(null);
  const [selectedLexofficeContactId, setSelectedLexofficeContactId] = React.useState<string>(null);
  const [selectedTaxType, setSelectedTaxType] = React.useState<LEXOFFICE_TAX_TYPE>(LEXOFFICE_TAX_TYPE.NET);
  const [selectedBillingLanguage, setSelectedBillingLanguage] = React.useState<LEXOFFICE_BILLING_LANGUAGE>(
    LEXOFFICE_BILLING_LANGUAGE.DE
  );

  React.useEffect(() => {
    setSelectedUserId(props?.data?.preselectedUser?.id);
    setSelectedLexofficeContactId(props?.data?.preselectedLexofficeContact?.id);

    const locale =
      props?.data?.preselectedUser?.locale?.toString() ||
      props?.data?.preselectedLexofficeContact?.addresses?.billing?.[0]?.countryCode?.toLowerCase();

    if (props?.data?.connection?.id) {
      setSelectedBillingLanguage(props?.data?.connection?.language);
    } else if (locale?.includes(LEXOFFICE_BILLING_LANGUAGE.DE)) {
      setSelectedBillingLanguage(LEXOFFICE_BILLING_LANGUAGE.DE);
    } else {
      setSelectedBillingLanguage(LEXOFFICE_BILLING_LANGUAGE.EN);
    }

    const matchedEuCountryCode = EU_COUNTRY_CODES.find(code => locale?.toString()?.includes(code));
    if (props?.data?.connection?.id) {
      setSelectedTaxType(props?.data?.connection?.taxType);
    } else if (matchedEuCountryCode === 'de') {
      setSelectedTaxType(LEXOFFICE_TAX_TYPE.NET);
    } else if (matchedEuCountryCode) {
      setSelectedTaxType(LEXOFFICE_TAX_TYPE.INTRA_COMMUNITY_SUPPLY);
    } else {
      setSelectedTaxType(LEXOFFICE_TAX_TYPE.THIRD_PARTY_COUNTRY_SERVICE);
    }
  }, [props]);

  const getSelectedLexofficeContact = (): LexofficeContact => {
    return props?.data?.lexofficeContacts?.find(c => c.id === selectedLexofficeContactId);
  };

  const onCreateNewConnection = async () => {
    if (!!props?.data?.connection?.id) {
      return;
    }

    const selectedLexofficeContact = getSelectedLexofficeContact();

    await props.onCreate(selectedUserId, selectedLexofficeContact, selectedTaxType, selectedBillingLanguage);
  };

  const onUpdateConnection = async () => {
    if (!props?.data?.connection?.id) {
      return;
    }

    const selectedLexofficeContact = getSelectedLexofficeContact();

    const connectionToUpdate: LexofficeConnection = {
      ...props?.data?.connection,
      contactId: selectedLexofficeContact.id,
      contactName:
        selectedLexofficeContact.company?.name ||
        `${selectedLexofficeContact.person?.firstName} ${selectedLexofficeContact.person?.lastName}`,
      customerNumber: selectedLexofficeContact.roles?.customer?.number,
      taxType: selectedTaxType,
      language: selectedBillingLanguage,
    };

    await props.onUpdate(selectedUserId, connectionToUpdate);
  };

  const onDeleteConnection = async () => {
    await props.onDelete(props.data?.connection?.userId, props.data?.connection?.id);
  };

  const generateContactPersonAsText = (contact: LexofficeContact) => {
    if (!contact?.company?.contactPersons?.length && !contact?.person) {
      return null;
    }

    if (contact?.company?.contactPersons?.length) {
      const firstContactPerson = contact.company?.contactPersons?.[0];
      return `${firstContactPerson?.firstName} ${firstContactPerson?.lastName}`;
    }

    return `${contact?.person?.firstName} ${contact?.person?.lastName}`;
  };

  const generateBillingAddressAsText = (contact: LexofficeContact) => {
    if (!contact?.addresses?.billing?.length) {
      return null;
    }

    const firstAddress = contact.addresses.billing[0];
    return `${firstAddress.street}, ${firstAddress.zip} ${firstAddress.city}, ${firstAddress.countryCode}`;
  };

  const getTitle = () => {
    return props?.data?.connection?.id
      ? `Edit connection with id=${props?.data?.connection?.id}`
      : 'Create new connection';
  };

  const getContent = () => {
    return (
      <>
        {props?.data?.connection?.id ? (
          <Grid container spacing={3}>
            <Grid item xs={6}>
              {/*ID*/}
              <TextField
                variant="outlined"
                margin="dense"
                type="number"
                fullWidth
                key="id"
                label="ID"
                name="id"
                value={props.data?.connection?.id}
                disabled={true}
              />
            </Grid>

            <Grid item xs={6}>
              {/*Created at*/}
              <TextField
                variant="outlined"
                margin="dense"
                type="text"
                fullWidth
                key="createdAt"
                label="Created at"
                name="createdAt"
                value={props.data?.connection?.createdAt}
                disabled={true}
              />
            </Grid>
          </Grid>
        ) : (
          <></>
        )}

        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              margin="dense"
              required
              select
              fullWidth
              label="User"
              name="user"
              disabled={!!props?.data?.connection?.id}
              value={selectedUserId || ''}
              onChange={(event: any) => setSelectedUserId(event.target?.value)}
            >
              {props?.data?.users.map(user => (
                <MenuItem value={user.id} key={user.id}>
                  [{user?.id}] [{user?.email}] [{user?.fullName}]
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={6}>
            <TextField
              variant="outlined"
              margin="dense"
              required
              select
              fullWidth
              label="Lexoffice contact"
              name="lexofficeContact"
              value={selectedLexofficeContactId || ''}
              onChange={(event: any) => setSelectedLexofficeContactId(event.target?.value)}
            >
              {props?.data?.lexofficeContacts.map(contact => (
                <MenuItem value={contact.id} key={contact.id}>
                  <div>{contact.id}</div>
                  <ul>
                    <li>Salon name: {contact?.company?.name}</li>
                    <li>Contact person: {generateContactPersonAsText(contact)} </li>
                    <li>Billing address: {generateBillingAddressAsText(contact)}</li>
                  </ul>
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              margin="dense"
              required
              select
              fullWidth
              label="Tax type"
              name="taxType"
              value={selectedTaxType || ''}
              onChange={(event: any) => setSelectedTaxType(event.target?.value)}
              helperText={
                <span>
                  'net' for DE, 'intraCommunitySupply' for other EU countries, 'thirdPartyCountryService' for other
                  countries
                </span>
              }
            >
              {taxTypeOptions.map(type => (
                <MenuItem value={type} key={type}>
                  {type}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={6}>
            <TextField
              variant="outlined"
              margin="dense"
              required
              select
              fullWidth
              label="Billing language"
              name="billingLanguage"
              value={selectedBillingLanguage || ''}
              onChange={(event: any) => setSelectedBillingLanguage(event.target?.value)}
            >
              {billingLanguageOptions.map(lang => (
                <MenuItem value={lang} key={lang}>
                  {lang}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </>
    );
  };

  const getButtons = () => {
    if (props?.data?.connection?.id) {
      return (
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              disabled={props.isLoading}
              onClick={onDeleteConnection}
            >
              Delete
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={props.isLoading}
              onClick={onUpdateConnection}
            >
              Save
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button fullWidth variant="contained" disabled={props.isLoading} onClick={() => props.onClose()}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      );
    } else {
      return (
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Button fullWidth variant="contained" disabled={props.isLoading} onClick={() => props.onClose()}>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={props.isLoading}
              onClick={onCreateNewConnection}
            >
              Create
            </Button>
          </Grid>
        </Grid>
      );
    }
  };

  return (
    <CustomDialogComponent
      isLoading={props.isLoading}
      styleOptions={{ fullWidth: true }}
      isOpen={props.isOpen}
      onClose={props.onClose}
      title={getTitle()}
      content={getContent()}
      buttons={getButtons()}
    ></CustomDialogComponent>
  );
}
