import React from 'react';
import { LexofficeContact } from '../../../../../models/interfaces/LexofficeContact.interface';
import {
  CustomDataTableConfig,
  HeadCell,
} from '../../../../../commons/components/custom-data-table/custom-data-table-config.model';
import CustomDataTableComponent from '../../../../../commons/components/custom-data-table/custom-data-table.component';

interface LexofficeContactTableComponentProps {
  lexofficeContacts: LexofficeContact[];
  onSelect?: Function;
}

export default function LexofficeContactTableComponent(props: LexofficeContactTableComponentProps) {
  const headCells: HeadCell[] = [
    { id: 'customerNumber', field: 'roles.customer.number', label: 'Customer number' },
    { id: 'salonName', label: 'Salon name', field: 'company.name' },
    {
      id: 'contactPerson',
      label: 'Contact person',
      getFieldValueFunc: (contact: LexofficeContact) => {
        if (!contact?.company?.contactPersons?.length && !contact?.person) {
          return null;
        }

        if (contact?.company?.contactPersons?.length) {
          const firstContactPerson = contact.company?.contactPersons?.[0];
          return `(${firstContactPerson?.salutation}) ${firstContactPerson?.firstName} ${firstContactPerson?.lastName}`;
        }

        return `(${contact?.person?.salutation}) ${contact?.person?.firstName} ${contact?.person?.lastName}`;
      },
    },
    {
      id: 'billingAddress',
      label: 'Billing address',
      getFieldValueFunc: (contact: LexofficeContact) => {
        if (!contact?.addresses?.billing?.length) {
          return null;
        }

        const firstAddress = contact.addresses.billing[0];
        return `[${firstAddress.countryCode}] ${firstAddress.street}, ${firstAddress.zip} ${firstAddress.city}`;
      },
    },
    {
      id: 'email',
      label: 'Email',
      getFieldValueFunc: (contact: LexofficeContact) => {
        if (!contact?.emailAddresses?.business?.length && !contact?.emailAddresses?.private?.length) {
          return null;
        }

        const emails: string[] = [];

        contact?.emailAddresses?.business?.forEach(e => {
          emails.push(`(business) ${e}`);
        });

        contact?.emailAddresses?.private?.forEach(e => {
          emails.push(`(private) ${e}`);
        });

        return emails;
      },
    },
    {
      id: 'phoneNumber',
      label: 'Phone number',
      getFieldValueFunc: (contact: LexofficeContact) => {
        if (!contact?.phoneNumbers?.business?.length && !contact?.phoneNumbers?.private?.length) {
          return null;
        }

        const phones: string[] = [];

        contact?.phoneNumbers?.business?.forEach(e => {
          phones.push(`(business) ${e}`);
        });

        contact?.phoneNumbers?.private?.forEach(e => {
          phones.push(`(private) ${e}`);
        });

        return phones;
      },
    },
    { id: 'note', field: 'note', label: 'Note' },
  ];

  const tableConfig: CustomDataTableConfig = {
    tableName: `Lexoffice contacts`,
    rowsPerPage: 10,
    headCells,
  };

  return (
    <CustomDataTableComponent tableConfig={tableConfig} data={props?.lexofficeContacts} onSelect={props?.onSelect} />
  );
}
