import React from 'react';
import { Box, LinearProgress, Tab, Tabs, Typography } from '@material-ui/core';
import { LexofficeConnection } from '../../../../models/interfaces/LexofficeConnection.interface';
import adminService from '../../../../services/admin.service';
import logger from '../../../../commons/logger';
import LexofficeConnectionTableComponent from './components/lexoffice-connection-table.component';
import { LexofficeContact } from '../../../../models/interfaces/LexofficeContact.interface';
import LexofficeContactTableComponent from './components/lexoffice-contact-table.component';
import { User } from '../../../../models/interfaces/User.interface';
import userService from '../../../../services/user.service';
import { NumberUtils } from '../../../../commons/utils/number.utils';
import UserTableComponent from '../user-context/components/user-table/user-table.component';
import {
  LexofficeConnectionDataForDialog,
  LexofficeConnectionDialogComponent,
} from './components/lexoffice-connection-dialog.component';
import { LEXOFFICE_TAX_TYPE } from '../../../../models/enums/LexofficeTaxType.enum';
import { LEXOFFICE_BILLING_LANGUAGE } from '../../../../models/enums/LexofficeBillingLanguage.enum';

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function LexofficePageComponent() {
  const [selectedTab, setSelectedTab] = React.useState<number>(0);
  const [lexofficeConnections, setLexofficeConnections] = React.useState<LexofficeConnection[]>([]);
  const [lexofficeContacts, setLexofficeContacts] = React.useState<LexofficeContact[]>([]);
  const [unassignedLexofficeContacts, setUnassignedLexofficeContacts] = React.useState<LexofficeContact[]>([]);
  const [users, setUsers] = React.useState<User[]>([]);
  const [unassignedUsers, setUnassignedUsers] = React.useState<User[]>([]);
  const [lexofficeConnectionDataForDialog, setLexofficeConnectionDataForDialog] =
    React.useState<LexofficeConnectionDataForDialog>(null);
  const [isOpenLexofficeConnectionDialog, setIsOpenLexofficeConnectionDialog] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const _fetchAllLexofficeConnections = async () => {
    const data = await adminService.fetchAllLexofficeConnections();
    setLexofficeConnections(data || []);

    return data;
  };

  const _init = async () => {
    setIsLoading(true);

    try {
      const connections = await _fetchAllLexofficeConnections();

      const setOfAssignedLexofficeContact = new Set<string>();
      connections?.forEach(c => setOfAssignedLexofficeContact.add(c.contactId));

      const lexofficeContacts = await adminService.fetchAllLexofficeContacts();
      setLexofficeContacts(lexofficeContacts);
      setUnassignedLexofficeContacts(
        lexofficeContacts?.filter(c => !setOfAssignedLexofficeContact.has(c.id) && !c.archived)
      );

      const setOfAssignedUser = new Set<number>();
      connections?.forEach(c => setOfAssignedUser.add(c.userId));

      const owners = await userService.getAllBusinessOwnerUsers();

      setUsers(owners);
      setUnassignedUsers(owners?.filter(o => !setOfAssignedUser.has(o.id)));
    } catch (e) {
      logger.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    _init();
  }, []);

  const closeLexofficeConnectionDialog = () => {
    setIsOpenLexofficeConnectionDialog(false);
    setLexofficeConnectionDataForDialog(null);
  };

  const onCloseLexofficeConnectionDialog = () => {
    if (isLoading) {
      return;
    }

    closeLexofficeConnectionDialog();
  };

  const _openLexofficeConnectionDialog = (
    lexofficeConnection: LexofficeConnection,
    preselectedLexofficeContact: LexofficeContact,
    preselectedUser: User
  ) => {
    if (isLoading) {
      return;
    }

    const selectedContact = lexofficeConnection
      ? lexofficeContacts?.find(c => c.id === lexofficeConnection.contactId)
      : null;
    const selectedUser = lexofficeConnection ? users?.find(u => u.id === lexofficeConnection.userId) : null;

    setLexofficeConnectionDataForDialog({
      connection: lexofficeConnection,
      users: selectedUser ? [selectedUser, ...unassignedUsers] : unassignedUsers,
      lexofficeContacts: selectedContact
        ? [selectedContact, ...unassignedLexofficeContacts]
        : unassignedLexofficeContacts,
      preselectedLexofficeContact: selectedContact || preselectedLexofficeContact,
      preselectedUser: selectedUser || preselectedUser,
    });

    setIsOpenLexofficeConnectionDialog(true);
  };

  const onSelectLexofficeConnection = (lexofficeConnection: LexofficeConnection) => {
    _openLexofficeConnectionDialog(lexofficeConnection, null, null);
  };

  const onSelectLexofficeContact = (lexofficeContact: LexofficeContact) => {
    _openLexofficeConnectionDialog(null, lexofficeContact, null);
  };

  const onSelectUser = (user: User) => {
    _openLexofficeConnectionDialog(null, null, user);
  };

  const onCreateLexofficeConnection = async (
    userId: number,
    contact: LexofficeContact,
    taxType: LEXOFFICE_TAX_TYPE,
    language: LEXOFFICE_BILLING_LANGUAGE
  ) => {
    setIsLoading(true);

    try {
      const createdLexofficeConnection = await adminService.createLexofficeConnection(
        userId,
        contact,
        taxType,
        language
      );

      if (createdLexofficeConnection?.id) {
        await _init();
        closeLexofficeConnectionDialog();
      }
    } catch (e) {
      logger.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const onUpdateLexofficeConnection = async (userId: number, connectionToUpdate: LexofficeConnection) => {
    setIsLoading(true);

    try {
      const updatedLexofficeConnection = await adminService.updateLexofficeConnection(userId, connectionToUpdate);

      if (updatedLexofficeConnection?.id) {
        await _init();
        closeLexofficeConnectionDialog();
      }
    } catch (e) {
      logger.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const onDeleteLexofficeConnection = async (userId: number, connectionId: number) => {
    setIsLoading(true);

    try {
      const isSuccess = await adminService.deleteLexofficeConnection(userId, connectionId);

      if (isSuccess) {
        await _init();
        closeLexofficeConnectionDialog();
      }
    } catch (e) {
      logger.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const tabChange = (event: any, tabIndex: number) => {
    setSelectedTab(tabIndex);
  };

  return (
    <>
      {isLoading ? <LinearProgress /> : <></>}

      {isOpenLexofficeConnectionDialog ? (
        <LexofficeConnectionDialogComponent
          isOpen={isOpenLexofficeConnectionDialog}
          isLoading={isLoading}
          data={lexofficeConnectionDataForDialog}
          onClose={onCloseLexofficeConnectionDialog}
          onCreate={onCreateLexofficeConnection}
          onUpdate={onUpdateLexofficeConnection}
          onDelete={onDeleteLexofficeConnection}
        ></LexofficeConnectionDialogComponent>
      ) : (
        <></>
      )}

      <Tabs
        value={selectedTab}
        onChange={tabChange}
        aria-label="Lexoffice contacts tabs"
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
      >
        <Tab label="Unassigned contacts" {...a11yProps(0)} />
        <Tab label="Assigned contacts" {...a11yProps(1)} />
      </Tabs>

      <TabPanel value={selectedTab} index={0}>
        <LexofficeContactTableComponent
          lexofficeContacts={unassignedLexofficeContacts}
          onSelect={onSelectLexofficeContact}
        ></LexofficeContactTableComponent>

        <UserTableComponent users={unassignedUsers} onSelect={onSelectUser}></UserTableComponent>
      </TabPanel>

      <TabPanel value={selectedTab} index={1}>
        <LexofficeConnectionTableComponent
          lexofficeConnections={lexofficeConnections}
          onSelect={onSelectLexofficeConnection}
        ></LexofficeConnectionTableComponent>
      </TabPanel>
    </>
  );
}
